<template>
  <div class="opage">
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span>
          {{ $fanyi("用户指南") }}
        </span>
      </template>
    </headGoBack>
    <van-tabs class="tabHead" v-model:active="active">
      <template v-for="(datas_item, datas_index) in datas" :key="datas_index">
        <van-tab
          :title="article_h5_item.title"
          v-for="(
            article_h5_item, article_h5_index
          ) in datas_item.guide_article_h5"
          :key="article_h5_index"
        >
          <div class="contBox">
            <div v-html="article_h5_item.content"></div>
          </div>
        </van-tab>
      </template>
    </van-tabs>
    <footBar />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const active = ref(0);

const datas = ref([]);
//------------------------ pageLoad -------------------------------------
const getData = () => {
  proxy.$api.articleListH5().then((res) => {
    if (res.code != 0) return false;
    datas.value = res.data;
  });
};
//------------------------ methods -------------------------------------
getData();
defineExpose({});
</script>
<style lang="scss" scoped>
.opage {
  background-color: #f6f6f6;
  min-height: 100vh;
  .tabHead {
    :deep().van-tabs__wrap {
      position: sticky;
      top: 100px;
      height: 68px;
      z-index: 1;
      .van-tab {
        height: 100%;
        span {
          font-size: 28px;
        }
      }
      .van-tab.van-tab--active {
        span {
          color: #b4272b;
        }
      }
      .van-tabs__line {
        width: 30px;
        background-color: #b4272b;
      }
    }
  }
  .contentDisplayBox {
    margin: 20px 30px 90px;
    background-color: white;
  }
}
:deep().coH1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 30px;
}
:deep().coH2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 15px;
}
:deep().coP {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 10px;
}
:deep().coP .red {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #b4272b;
}

.contBox,
/deep/.contBox {
  padding: 30px;
  margin: 30px;
  background-color: white;
  * {
    font-size: 24px;
    line-height: 1.4;
  }
  p {
    margin: 10px 0;
    overflow: auto;
  }
  strong {
    font-weight: 700;
  }
}
</style>
